import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import ContentLoader from 'react-content-loader';
import { Heading3 } from '../../atoms/Headings/Headings';
import Card from '../../atoms/Card/Card';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  buttonSpacing,
  colors,
  fontSizes,
  pageDimensions,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import Image from '../../atoms/Image/Image';
import Quote from '../Quote/Quote';
import AnimatedButton from '../../atoms/Buttons/AnimatedButton/AnimatedButton';
import VideoButton from '../../atoms/Buttons/VideoButton/VideoButton';
import Section from '../Section/Section';

const ClientCarouselCardSkeleton = () => (
  <ContentLoader
    width={300}
    height={80}
    speed={2}
    primaryColor={colors.lightGray.one}
    secondaryColor={colors.lightGray.two}
  >
    <rect x="0" y="0" rx="10" ry="10" width="60" height="80" />
    <rect x="45" y="0" rx="0" ry="0" width="60" height="80" />
    <rect x="110" y="10" rx="4" ry="4" width="170" height="10" />
    <rect x="110" y="30" rx="4" ry="4" width="100" height="10" />
    <rect x="110" y="45" rx="4" ry="4" width="100" height="10" />
    <rect x="110" y="60" rx="4" ry="4" width="100" height="10" />
  </ContentLoader>
);

const ClientCarouselCard = ({
  animatedButton,
  carouselBg,
  carouselHeading,
  clientLogo,
  sectionStyles,
  videoButton,
  ...props
}) => {
  const cardCss = css`
    position: relative;
    z-index: -1;
    display: flex;
    justify-content: center;
    width: ${pageDimensions.desktopWidth};
    height: ${uc('455px')};
    margin: 0 auto;
    overflow: hidden;

    @media (${breakpoints.largeTablet}) {
      width: calc(100% - ${uc('40px')});
      height: auto;
    }
  `;

  const backgroundCss = css`
    position: absolute;
    left: 0;
    width: 37.5%;
    height: 100%;
    background-size: cover;
    clip-path: polygon(0 0, 74% 0, 100% 100%, 0% 100%);

    @media (${breakpoints.largeTablet}) {
      width: 100%;
      clip-path: none;
    }
  `;

  const cardContentCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin-left: 37%;
    padding: ${uc('0 100px 0 72px')};

    @media (${breakpoints.largeTablet}) {
      width: 100%;
      margin-left: 0;
      padding: ${uc('48px')};
      background-color: #fffc;
    }
  `;

  const heading3Css = css`
    position: relative;
    top: 10px;
    max-width: ${uc('668px')};
    font-size: ${fontSizes.twentyThree};
  `;

  const clientImageCss = css`
    position: absolute;
    top: ${uc('-16px')};
    left: ${uc('-132px')};
    width: ${uc('140px')};
    height: ${uc('140px')};
    background: ${colors.white};
    border-radius: 50%;
    box-shadow: ${shadows.large};
    transform: translateX(-50%);
    object-fit: contain;
  `;

  const quoteCss = css`
    position: relative;
    margin-bottom: ${uc('20px')};

    @media (${breakpoints.largeTablet}) {
      img {
        display: none;
      }
    }
  `;

  const buttonContainerCss = css`
    position: relative;
    display: flex;

    .video-button {
      margin-right: ${buttonSpacing.marginRight};

      img {
        display: inline-block;
      }
    }

    @media (${breakpoints.mobile}) {
      display: block;

      button,
      a {
        display: block;
        width: 100%;
        margin-right: 0;
      }
    }
  `;

  // react-slick adds a style prop, so removing here
  const cleanProps = Object.assign({}, props);
  delete cleanProps.style;

  const backgroundImage = {
    backgroundImage: `url(${sanityImage(carouselBg)
      .auto('format')
      .width(600)
      .url()})`,
  };

  return (
    <Section sectionStyles={sectionStyles}>
      <Card
        cardSkeleton={<ClientCarouselCardSkeleton />}
        css={cardCss}
        className="carousel-card"
      >
        <div css={backgroundCss} style={backgroundImage} />
        <div css={cardContentCss}>
          <Heading3 css={heading3Css}>
            {clientLogo && (
              <Image
                css={clientImageCss}
                src={sanityImage(clientLogo)
                  .auto('format')
                  .width(150)
                  .url()}
                alt={clientLogo.alt}
              />
            )}
            {carouselHeading}
          </Heading3>
          <Quote css={quoteCss} {...cleanProps} />
          <div css={buttonContainerCss}>
            {videoButton && videoButton.buttonText && (
              <VideoButton className="video-button" {...videoButton}>
                {videoButton.buttonText}
              </VideoButton>
            )}
            {animatedButton && animatedButton.link.text && (
              <AnimatedButton {...animatedButton}>
                {animatedButton && animatedButton.link.text}
              </AnimatedButton>
            )}
          </div>
        </div>
      </Card>
    </Section>
  );
};

ClientCarouselCard.propTypes = {
  animatedButton: PropTypes.shape({
    link: PropTypes.shape({ text: PropTypes.string }),
  }),
  carouselBg: PropTypes.shape({}),
  carouselHeading: PropTypes.string,
  clientLogo: PropTypes.shape({ alt: PropTypes.string }),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  videoButton: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
};

ClientCarouselCard.defaultProps = {
  animatedButton: null,
  carouselBg: {},
  carouselHeading: '',
  clientLogo: {},
  sectionStyles: {},
  videoButton: null,
};

export default ClientCarouselCard;
